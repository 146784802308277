import { UnsafeAction as Action } from '../../interfaces';

const ACTION_PREFIX = '[PermissionGroup]';

export const permissionGroupActionType = {
  LOAD: `${ACTION_PREFIX} Load`,
  LOAD_SUCCESS: `${ACTION_PREFIX} Load Success`,
  LOAD_FAILED: `${ACTION_PREFIX} Load Failed`,

  ADD: `${ACTION_PREFIX} Add`,
  ADD_SUCCESS: `${ACTION_PREFIX} Add Success`,
  ADD_FAILED: `${ACTION_PREFIX} Add Failed`,

  UPDATE: `${ACTION_PREFIX} Update`,
  UPDATE_SUCCESS: `${ACTION_PREFIX} Update Success`,
  UPDATE_FAILED: `${ACTION_PREFIX} Update Failed`,

  FETCH: `${ACTION_PREFIX} Fetch`,
  FETCH_SUCCESS: `${ACTION_PREFIX} Fetch Success`,
  FETCH_FAILED: `${ACTION_PREFIX} Fetch Failed`,
};

export class PermissionGroupAction {
  static load(): Action {
    return {
      type: permissionGroupActionType.LOAD,
    };
  }

  static loadSuccess(response): Action {
    return {
      type: permissionGroupActionType.LOAD_SUCCESS,
      payload: response,
    };
  }

  static loadFailed(err): Action {
    return {
      type: permissionGroupActionType.LOAD_FAILED,
      payload: err,
    };
  }

  static add(permissionGroupData): Action {
    return {
      type: permissionGroupActionType.ADD,
      payload: permissionGroupData,
    };
  }

  static addSuccess(permissionGroupResponse): Action {
    return {
      type: permissionGroupActionType.ADD_SUCCESS,
      payload: permissionGroupResponse,
    };
  }

  static addFailed(err): Action {
    return {
      type: permissionGroupActionType.ADD_FAILED,
      payload: err,
    };
  }

  static update(permissionGroupId, permissionGroupData): Action {
    return {
      type: permissionGroupActionType.UPDATE,
      payload: permissionGroupData,
    };
  }

  static updateSuccess(permissionGroupResponse): Action {
    return {
      type: permissionGroupActionType.UPDATE_SUCCESS,
      payload: permissionGroupResponse,
    };
  }

  static updateFailed(err): Action {
    return {
      type: permissionGroupActionType.UPDATE_FAILED,
      payload: err,
    };
  }

  static fetch(id: any) {
    return {
      type: permissionGroupActionType.FETCH,
      payload: id,
    };
  }

  static fetchSuccess(response) {
    return {
      type: permissionGroupActionType.FETCH_SUCCESS,
      payload: response,
    };
  }

  static fetchFailed(id: string, err) {
    return {
      type: permissionGroupActionType.FETCH_SUCCESS,
      payload: {
        id,
        err,
      },
    };
  }
}
