import { Injectable } from '@angular/core';
import { compose, Store } from '@ngrx/store';
import { SelectItem } from '@sb/ui';
import { createSelector } from 'reselect';
import { Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import u from 'updeep';

import { AppState } from '../../index';
import { mapEntity } from '../../shared/entity.helper';
import { PermissionGroupAction } from './permission-group.action';
import { PermissionGroupApi } from './permission-group.api';
import { PermissionGroupState } from './permission-group.model';

@Injectable()
export class PermissionGroupService {
  public constructor(
    private store: Store<AppState>,
    private api: PermissionGroupApi,
  ) {}

  public load() {
    this.store.dispatch(PermissionGroupAction.load());

    return this.api.load().pipe(
      map((response) => {
        this.store.dispatch(PermissionGroupAction.loadSuccess(response));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(PermissionGroupAction.loadFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public fetch(id) {
    this.store.dispatch(PermissionGroupAction.fetch(id));

    return this.api.fetch(id).pipe(
      map((response) => {
        this.store.dispatch(PermissionGroupAction.fetchSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(PermissionGroupAction.fetchFailed(id, response));
        return observableThrowError(response);
      }),
    );
  }

  public add(locationData): Observable<any> {
    this.store.dispatch(PermissionGroupAction.add(locationData));

    return this.api.add(locationData).pipe(
      map((response) => {
        this.store.dispatch(PermissionGroupAction.addSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(PermissionGroupAction.addFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public update(id, locationData) {
    this.store.dispatch(PermissionGroupAction.update(id, locationData));

    return this.api.update(id, locationData).pipe(
      map((response) => {
        this.store.dispatch(PermissionGroupAction.updateSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(PermissionGroupAction.updateFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public save(locationData) {
    if (locationData.id) {
      return this.update(locationData.id, locationData);
    }

    locationData = u.omit('id', locationData);

    return this.add(locationData);
  }
}

export const getPermissionGroupState = (appState: AppState): PermissionGroupState => appState.orm.permissionGroups;

export const getPermissionGroupIds = compose((state) => state.items, getPermissionGroupState);
export const getPermissionGroupEntities = createSelector(getPermissionGroupState, (state) => state.itemsById);

export const getPermissionGroups = createSelector(getPermissionGroupState, (state: PermissionGroupState) =>
  Object.values(state.itemsById),
);
export const getPermissionGroup = (id: string) =>
  createSelector(getPermissionGroupEntities, (entities) => mapEntity(id, entities));

export const getPermissionGroupOptions = createSelector(getPermissionGroups, (permissionGroups): SelectItem[] =>
  permissionGroups.map(
    (permissionGroup): SelectItem => ({
      text: permissionGroup.name,
      value: permissionGroup.id,
    }),
  ),
);
