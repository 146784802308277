import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { arrayOf, normalize } from 'normalizr';
import { PermissionGroupSchema } from '../../shared/schemas';
import { reformatEntityResponse, reformatListResponse } from '../../shared/entity.helper';
import { ApiGateway } from '../../../api/ApiGateway.service';
import { assignSchemaEntity } from '../../shared/assign';

function assignPermissionGroup(obj, key, val, originalInput, schema) {
  var itemSchema = schema && schema.getItemSchema ? schema.getItemSchema() : schema;
  var removeProps = itemSchema && itemSchema.getMeta && itemSchema.getMeta('removeProps');

  if (removeProps && Array.isArray(removeProps) && removeProps.indexOf(key) >= 0) {
    return;
  }

  if (key === 'GroupsPermission') {
    obj['Permission'] = originalInput.GroupsPermission.map((permission) => permission.permission_id);
    return;
  }

  obj[key] = val;
}

@Injectable()
export class PermissionGroupApi {
  private endpoint = 'groups/';

  constructor(private gateway: ApiGateway) {}

  load(): Observable<any> {
    const params = { include: 'GroupsPermission' };

    return this.gateway
      .get(this.endpoint, {
        params: params,
      })
      .pipe(
        map((res) => reformatListResponse('Group', res)),
        map((data) => normalize(data, arrayOf(PermissionGroupSchema), { assignEntity: assignPermissionGroup }))
      );
  }

  add(permissionGroupData): Observable<any> {
    return this.gateway.post(this.endpoint, permissionGroupData).pipe(
      map((res) => reformatEntityResponse('Group', res)),
      map((data) => normalize(data, PermissionGroupSchema, { assignEntity: assignPermissionGroup }))
    );
  }

  update(permissionGroupId, permissionGroupData): Observable<any> {
    return this.gateway
      .put(this.endpoint + '/' + permissionGroupId, permissionGroupData, { params: 'allow_deleted=1' })
      .pipe(
        map((res) => reformatEntityResponse('Group', res)),
        map((data) => normalize(data, PermissionGroupSchema, { assignEntity: assignPermissionGroup }))
      );
  }

  fetch(permissionGroupId): Observable<any> {
    return this.gateway.get(this.endpoint + '/' + permissionGroupId, { params: 'allow_deleted=1' }).pipe(
      map((res) => reformatEntityResponse('Group', res)),
      map((data) => normalize(data, PermissionGroupSchema, { assignEntity: assignPermissionGroup }))
    );
  }
}
